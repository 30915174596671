export const relocateOwnershipUnitVisuals = [
  {
    name: "Demo",
    markdown: `![Relocate ownership unit GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const relocateOwnershipUnit = {
  order: 39,
  name: "Relocate a floor area ownership unit",
  keyword: "condominium co-op coop move",
  subcategory: "Properties",
  markdown: `# Relocate a floor area ownership unit

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  When a {% inlineRouterLink articleId="add-an-ownership-unit" %}condominium or co-op unit{% /inlineRouterLink %} is attached to {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %}, it may become necessary to move or extend the ownership unit to a different floor area (or floor areas). Read on to learn how to do this in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.

  {% callout type="tip" %}
  **Tip:** To use this feature the ownership unit must be attached to floor areas.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} an {% inlineAppIcon iconName="cube" /%} {% inlineRouterLink articleId="add-an-ownership-unit" %}ownership unit{% /inlineRouterLink %} that is attached to one or more {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="relocateCondoCoop" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %} to move the ownership unit to.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. The {% inlineAppIcon iconName="cube" /%} ownership unit appears on the selected diagram parts.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$relocateOwnershipUnitVisuals /%}
  `,
};
